export interface SearchParams {
  searchCategory: string | undefined;
  searchTerm: string | undefined;
  quickSearch: string | undefined;
}

enum FeatureCategory {
  client = 'client',
  scope = 'scope',
  firm = 'firm',
  user = 'user',
  brand = 'brand',
  admin = 'admin',
  token = 'token'
}

enum QuickSearchParamKey {
  quickSearchKey = 'f=',
  searchTermKey = 'q='
}

const QuickSearchSplitByDelimiter = ',';

const HeaderMenuOptions = [
  {
    label: 'Token',
    value: FeatureCategory.token
  },
  {
    label: 'Client',
    value: FeatureCategory.client
  },
  {
    label: 'Scope',
    value: FeatureCategory.scope
  }
];

const HeaderMenuAdminOptions = [
  ...HeaderMenuOptions,
  {
    label: 'Firm',
    value: FeatureCategory.firm
  },
  {
    label: 'User',
    value: FeatureCategory.user
  },
  {
    label: 'Brand',
    value: FeatureCategory.brand
  },
  {
    label: 'Admin',
    value: FeatureCategory.admin
  }
];

export enum QuickSearchType {
  web = 'web',
  native = 'native',
  api = 'service',
  marketPlace = 'marketPlace',
  myClients = 'myClients',
  active = 'active',
  archive = 'archive',
  au = 'au',
  us = 'us',
  uk = 'uk',
  ca = 'ca',
  regularUser = 'auth-user',
  marketplaceUser = 'auth-marketplace',
  adminUser = 'auth-admin'
}

export type QuickSearchOption = {
  label: string;
  qsKey: string;
  selected: boolean;
};

const ClientQuickOptions: QuickSearchOption[] = [
  {
    label: 'My Clients',
    qsKey: QuickSearchType.myClients,
    selected: false
  },
  {
    label: 'Web',
    qsKey: QuickSearchType.web,
    selected: true
  },
  {
    label: 'Native',
    qsKey: QuickSearchType.native,
    selected: true
  },
  {
    label: 'API',
    qsKey: QuickSearchType.api,
    selected: true
  },
  {
    label: 'Marketplace',
    qsKey: QuickSearchType.marketPlace,
    selected: false
  }
];

const FirmQuickOptions: QuickSearchOption[] = [
  {
    label: 'AU',
    qsKey: QuickSearchType.au,
    selected: true
  },
  {
    label: 'UK',
    qsKey: QuickSearchType.uk,
    selected: true
  },
  {
    label: 'US',
    qsKey: QuickSearchType.us,
    selected: true
  },
  {
    label: 'CA',
    qsKey: QuickSearchType.ca,
    selected: true
  },
  {
    label: 'Inc. Archive',
    qsKey: QuickSearchType.archive,
    selected: false
  }
];

const UserQuickOptions: QuickSearchOption[] = [
  {
    label: 'Regular User',
    qsKey: QuickSearchType.regularUser,
    selected: true
  },
  {
    label: 'Marketplace User',
    qsKey: QuickSearchType.marketplaceUser,
    selected: true
  },
  {
    label: 'Admin User',
    qsKey: QuickSearchType.adminUser,
    selected: true
  }
];

const QuickSearchLink = {
  client: ClientQuickOptions,
  firm: FirmQuickOptions,
  user: UserQuickOptions
};

const readQueryParam = (searchTerm, queryKey) => {
  const value = searchTerm?.find((x) => x.indexOf(queryKey) !== -1)?.split(queryKey)[1] || '';
  return decodeURIComponent(value);
};

const getSearchQueryString = (location) => {
  const defaultCategory = location.pathname?.split('/')[1] || FeatureCategory.client;
  const searchTerm = location.search?.split('&');
  const defaultSearchTerm = readQueryParam(searchTerm, QuickSearchParamKey.searchTermKey);
  const defaultQuickSearch = readQueryParam(searchTerm, QuickSearchParamKey.quickSearchKey);

  return {
    category: defaultCategory,
    searchTerm: defaultSearchTerm,
    quickSearch: defaultQuickSearch
  };
};

export {
  getSearchQueryString,
  QuickSearchParamKey,
  QuickSearchSplitByDelimiter,
  HeaderMenuOptions,
  HeaderMenuAdminOptions,
  QuickSearchLink,
  FeatureCategory
};
