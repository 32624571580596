import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { AuthAgent } from '@leapdev/auth-agent';
import { SnackbarProvider } from 'notistack';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './AppTheme';
import { AuthConf } from './core/conf';
import { AuthContext, EAuthAdminRole } from './core/context';
import { Logger } from './core/service/log.service';

// Authenticate first
AuthAgent.init(AuthConf)
  .then((accessToken) => {
    if (accessToken && typeof accessToken === 'string') {
      Logger.info('fetching user details from AuthAgent');
      return AuthAgent.userInfo();
    } else {
      return undefined;
    }
  })
  .then((userInfo) => {
    if (!userInfo) {
      return;
    }
    Logger.info('userInfo', userInfo);
    const logout = AuthAgent.logout;
    const { firstName, lastName, email, userId } = userInfo;
    const decodeAccessToken = AuthAgent.getDecodedAccessToken();
    const { roles } = decodeAccessToken;
    let role = EAuthAdminRole.Azure;
    if (roles?.includes('auth-admin')) {
      role = EAuthAdminRole.Admin;
    } else if (roles?.includes('auth-marketplace')) {
      role = EAuthAdminRole.Marketplace;
    } else if (roles?.includes('auth-user')) {
      role = EAuthAdminRole.User;
    }
    const auth = {
      firstName,
      lastName,
      email,
      logout,
      role,
      fullName: `${firstName} ${lastName}`,
      id: userId
    };
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <AuthContext.Provider value={auth}>
            <App />
          </AuthContext.Provider>
        </SnackbarProvider>
      </ThemeProvider>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
