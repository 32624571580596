import { createContext } from 'react';

export enum EAuthAdminRole {
  Azure, // Authenticated with LEAP Microsoft Account
  User, //  the user can only see clients he is owner of
  Marketplace, // the user can see clients he is owner of as well as marketplace clients
  Admin // the user can see all the screens of Auth Admin, including all the clients with admin privileges
}
export interface Auth {
  firstName: string;
  lastName: string;
  email: string;
  role: EAuthAdminRole;
  id: string;
  fullName: string;
  logout: () => any;
}

const AuthContext = createContext<Auth>({
  firstName: '',
  lastName: '',
  email: '',
  role: EAuthAdminRole.Azure,
  id: '',
  fullName: '',
  logout: () => {}
});

export { AuthContext };
