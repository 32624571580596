import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { AuthContext, EAuthAdminRole } from '../context';

import { BtnIcon, BtnText, NavLink } from '../../component';
import { HeaderMenuOptions, HeaderMenuAdminOptions } from '../model';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '3em',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '0 1em'
  },
  navSection: {
    marginLeft: theme.spacing(4)
  },
  navBtn: {
    color: theme.palette.common.white
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.8em'
  },
  authBtn: {
    color: theme.palette.common.white
  },
  userSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto'
  }
}));

const HeaderPage = () => {
  const classes = useStyle();
  const auth = useContext(AuthContext);

  const handleClickOnAuthBtn = () => {
    if (!!auth) {
      auth.logout();
    }
  };

  return (
    <>
      <section className={classes.root}>
        <div>
          <h3>Auth Admin - {process.env.REACT_APP_HOST_ENV?.toLocaleUpperCase()}</h3>
        </div>
        <div className={classes.navSection}>
          {(auth.role === EAuthAdminRole.Admin ? HeaderMenuAdminOptions : HeaderMenuOptions).map((d) => (
            <BtnText className={classes.navBtn} key={d.value} role="link" component={NavLink} to={`/${d.value}`}>
              {d.label}
            </BtnText>
          ))}
        </div>
        <div className={classes.userSection}>
          <div>
            {auth ? (
              <div className={classes.userInfo}>
                <span>
                  {auth.firstName} {auth.lastName}
                </span>
                <span>{auth.email}</span>
              </div>
            ) : null}
          </div>
          <div>
            <BtnIcon
              size="large"
              icon="exitToApp"
              className={classes.authBtn}
              onClick={(_) => handleClickOnAuthBtn()}
            ></BtnIcon>
          </div>
        </div>
      </section>
    </>
  );
};

export { HeaderPage };
