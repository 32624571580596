import React, { useCallback, useEffect, useState } from 'react';
import { InputBase, makeStyles, Paper, Theme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useNavigate } from '@reach/router';

import { BtnIcon, BtnText } from '../../component';
import {
  FeatureCategory,
  QuickSearchOption,
  QuickSearchParamKey,
  QuickSearchSplitByDelimiter
} from '../model/search.model';
import { useAppState, useDebounce, useSearch } from '../hook';
import { AppActionType } from '../context';
import { ResponsiveDialog } from '../../component/dialog';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: '4em'
  },
  paper: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  links: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      margin: theme.spacing(1)
    },
    '& > button[aria-selected="true"] > span.MuiButton-label': {
      borderBottom: '1pt solid'
    },
    minHeight: theme.spacing(6)
  }
}));

const SearchPage = (props) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { appState, dispatch } = useAppState();
  const [openWarning, setOpenWarning] = useState(false);
  const [disableAddNewBtn, setDisableAddNewBtn] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(undefined);

  const [category, searchTerm, quickSearch, quickSearchOptions, quickSearchCriteria] = useSearch();

  const debouncedSearchTerm = useDebounce(searchInputValue, 1000);

  const proceedSearchNavigation = useCallback(
    (quickSearchTerms: string[], searchTerm: string) => {
      setOpenWarning(false);
      if (dispatch) {
        dispatch({ type: AppActionType.ASK_BEFORE_NAVIGATE, payload: false });
      }

      if (!category) {
        return;
      }

      let newPath = searchTerm ? `/${category}?${QuickSearchParamKey.searchTermKey}${searchTerm}` : `/${category}`;

      if (!!quickSearchTerms && quickSearchTerms.length > 0) {
        const qs = quickSearchTerms.join(QuickSearchSplitByDelimiter);
        newPath =
          newPath.indexOf(QuickSearchParamKey.searchTermKey) !== -1
            ? `${newPath}&${QuickSearchParamKey.quickSearchKey}${qs}`
            : `${newPath}?${QuickSearchParamKey.quickSearchKey}${qs}`;
      }

      navigate(newPath, { replace: true });
    },
    [dispatch, category, navigate]
  );

  const proceedAddNewNavigation = () => {
    setOpenWarning(false);
    if (dispatch) {
      dispatch({ type: AppActionType.ASK_BEFORE_NAVIGATE, payload: false });
    }

    if (!category) {
      return;
    }

    navigate(`/${category}/new`);
  };

  const navigateAddNew = () => {
    proceedAddNewNavigation();
  };

  const refineQuickSearch = (option: QuickSearchOption) => {
    const quickSearchTerms = quickSearchOptions
      .map((o) => {
        return o.qsKey === option.qsKey ? { ...o, selected: !o.selected } : o;
      })
      .filter((o) => o.selected)
      .map((o) => o.qsKey);
    proceedSearchNavigation(quickSearchTerms, searchTerm);
  };

  useEffect(() => {
    proceedSearchNavigation(quickSearchCriteria, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const disable = category === FeatureCategory.firm || category === FeatureCategory.scope;
    setDisableAddNewBtn(disable);
  }, [category]);

  const confirmNavigation = () => {
    if (appState.askBeforeNavigate && dispatch) {
      setOpenWarning(true);
      return;
    }

    proceedSearchNavigation(quickSearchCriteria, searchTerm);
  };

  return (
    <>
      <ResponsiveDialog
        open={openWarning}
        data={{
          title: 'Navigation Confirmation',
          text: 'You have unsaved changes. Do you still want continue the navigation?',
          ok: {
            label: 'Navigate',
            action: () => confirmNavigation()
          },
          cancel: {
            label: 'Cancel',
            action: () => setOpenWarning(false)
          }
        }}
      ></ResponsiveDialog>
      <Container maxWidth="sm">
        <div className={classes.links}>
          {!!quickSearchOptions && quickSearchOptions!.length > 0
            ? quickSearchOptions!.map((d) => (
                <BtnText
                  key={d.label}
                  selected={d.selected}
                  color="secondary"
                  size="small"
                  onClick={(_) => refineQuickSearch(d)}
                >
                  {d.label}
                </BtnText>
              ))
            : null}
        </div>
        <Paper className={classes.paper}>
          <InputBase
            className={classes.input}
            value={searchInputValue !== undefined ? searchInputValue : searchTerm}
            placeholder={`Search ${category}...`}
            onChange={(e) => setSearchInputValue(e.target.value)}
          />
          <BtnIcon
            size="small"
            icon="addCircle"
            title={`Add new ${category}`}
            onClick={() => navigateAddNew()}
            disabled={disableAddNewBtn}
            color="primary"
          ></BtnIcon>
        </Paper>
      </Container>
    </>
  );
};

export { SearchPage };
