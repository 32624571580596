import React, { Suspense, lazy, useContext } from 'react';
import { Router, Redirect, RouteComponentProps } from '@reach/router';
import { AuthContext, EAuthAdminRole } from '../core/context';

const ClientLayout = lazy(() => import('./client/ClientLayout'));
const ScopeLayout = lazy(() => import('./scope/ScopeLayout'));
const UserLayout = lazy(() => import('./user/UserLayout'));
const FirmLayout = lazy(() => import('./firm/FirmLayout'));
const BrandLayout = lazy(() => import('./brand/BrandLayout'));
const AdminLayout = lazy(() => import('./admin/AdminLayout'));
const TokenLayout = lazy(() => import('./token/TokenLayout'));

const Routes = () => {
  const authContext = useContext(AuthContext);
  const NotFound = (props: RouteComponentProps) => <p>Sorry, nothing here</p>;
  return (
    <Suspense fallback={<span>still working hard to deliver your content...</span>}>
      <Router>
        <ClientLayout path="client/*" />
        <ScopeLayout path="scope/*" />
        <TokenLayout path="token/*" />
        {authContext.role === EAuthAdminRole.Admin && (
          <>
            <UserLayout path="user/*" />
            <FirmLayout path="firm/*" />
            <BrandLayout path="brand/*" />
            <AdminLayout path="admin/*" />
          </>
        )}

        <Redirect noThrow from="/" to="client"></Redirect>
        <NotFound default />
      </Router>
    </Suspense>
  );
};

export { Routes };
