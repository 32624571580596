import { AuthAgent } from '@leapdev/auth-agent';

import { UserDetails, UserInfo } from '../model';
import { Logger } from './log.service';

export function logout() {
  return AuthAgent.logout();
}

export async function getAccessToken(): Promise<string> {
  return AuthAgent.getRefreshedAccessToken();
}

export function getDecodedAccessToken(): UserInfo {
  return AuthAgent.getDecodedAccessToken();
}

export async function getUserDetails() {
  Logger.info('fetching user details from AuthAgent');
  const userInfo = await AuthAgent.userInfo();
  return (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo) as UserDetails;
}
