import TextField from '@material-ui/core/TextField';
import React from 'react';

const FullText = (props) => {
  return (
    <TextField
      {...props}
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
    ></TextField>
  );
};

export { FullText };
