import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const ScrollShowAppBar = (props) => {
  const { children, targetRef, alwaysAppear } = props;

  const trigger = useScrollTrigger({ target: targetRef || window });

  return (
    <>
      <Slide
        appear={alwaysAppear || false}
        direction="down"
        in={alwaysAppear !== undefined || trigger === null ? true : !trigger}
      >
        <AppBar color="inherit" position="sticky">
          <Toolbar variant="dense">{children}</Toolbar>
        </AppBar>
      </Slide>
    </>
  );
};

export { ScrollShowAppBar };
