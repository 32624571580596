import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

type BtnTextProps = {
  selected?: boolean;
};

const BtnText = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }> & BtnTextProps) => {
  const { selected, ...rest } = props;
  return (
    <Button aria-selected={selected} {...rest}>
      {props.children}
    </Button>
  );
};

export { BtnText };
