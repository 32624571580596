import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

interface FullSwitchV2Props extends SwitchProps {
  label: string;
}

const FullSwitch = (props: FullSwitchV2Props) => {
  const { label, ...switchProps } = props;
  return (
    <>
      <FormControlLabel label={label} control={<Switch {...switchProps} />}></FormControlLabel>
    </>
  );
};

export { FullSwitch };
