import React from 'react';
import { Location } from '@reach/router';

import { HeaderPage, MainPage } from '../page';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 9.5rem)'
  }
}));

const MainLayout = (props) => {
  const classes = useStyle();

  return (
    <section className={classes.root}>
      <Location>
        <HeaderPage></HeaderPage>
        <MainPage></MainPage>
      </Location>
    </section>
  );
};

export { MainLayout };
