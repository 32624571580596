import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { BtnText } from '../button';

export interface ResponsiveDialogProps extends DialogProps {
  data?:
    | {
        title: string;
        text?: string;
        text2?: string;
        ok: {
          label: string;
          action: (data?: any) => void;
          disabled?: boolean;
        };
        cancel: {
          label: string;
          action: (data?: any) => void;
        };
      }
    | undefined;
}

const ResponsiveDialog = (props: ResponsiveDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={props.open}
      onClose={props.data?.cancel.action}
      fullScreen={fullScreen}
      disableBackdropClick={props.disableBackdropClick}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      maxWidth={false}
    >
      <DialogTitle>{props.data?.title}</DialogTitle>
      <DialogContent dividers>
        {props.data?.text && <DialogContentText>{props.data?.text}</DialogContentText>}
        {props.data?.text2 && <DialogContentText>{props.data?.text2}</DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions>
        <BtnText onClick={props.data?.cancel.action} color="primary">
          {props.data?.cancel.label || 'Cancel'}
        </BtnText>
        <BtnText
          label={props.data?.ok.label}
          disabled={props.data?.ok.disabled ?? false}
          onClick={props.data?.ok.action}
          color="secondary"
        >
          {props.data?.ok.label || 'Confirm'}
        </BtnText>
      </DialogActions>
    </Dialog>
  );
};

export { ResponsiveDialog };
