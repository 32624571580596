import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { BtnIcon } from '../button';
import { FullText } from './FullText';
import { RequiredValidator } from '../../core/model/form.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootAddFlex: {
      display: 'flex'
    },
    rootDeleteFlex: {
      display: 'flex',
      marginTop: '10pt'
    }
  })
);

const ControlAddNew = (props) => {
  const { disabled } = props;
  const [value, setValue] = useState('');
  const [required, setRequired] = useState(false);

  return (
    <div className={props.className}>
      <FullText
        id={props.id ? `${props.id}-text-new` : undefined}
        value={value}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        label={props.label}
        error={required}
        helperText={required ? RequiredValidator.required : ''}
      ></FullText>{' '}
      <BtnIcon
        size="small"
        icon="addCircle"
        disabled={disabled}
        title={props.addNewTooltip || 'Add New'}
        onClick={() => {
          if (!!!value) {
            setRequired(true);
            setTimeout(() => setRequired(false), 2000);
            return;
          }

          setRequired(false);
          props.add(value);
          setValue('');
        }}
        color="primary"
      ></BtnIcon>
    </div>
  );
};

const ControlEntry = (props) => {
  const { disabled } = props;
  return (
    <div className={props.className}>
      <FullText
        id={props.id ? `${props.id}-text-edit-${props.deleteIdx}` : undefined}
        defaultValue={props.item}
        disabled={disabled}
        onChange={(e) => props.changing(e.target.value, props.deleteIdx)}
      ></FullText>
      <BtnIcon
        size="small"
        icon="delete"
        disabled={disabled}
        title={props.deleteTooltip || 'Delete'}
        onClick={() => props.delete(props.deleteIdx)}
        color="primary"
      ></BtnIcon>
    </div>
  );
};

const FullTextList = (props) => {
  const classes = useStyles();
  const { disabled } = props;
  const [data, setData] = useState<string[]>([]);
  const [deleteIdx, setDeleteIdx] = useState<number>(-1);
  const [updateIdx, setUpdateIdx] = useState<{ item: string; idx: number }>();

  const handleDelete = (deleteidx) => {
    setDeleteIdx(deleteidx);
  };

  const handleChange = (newItem, idx) => {
    setUpdateIdx({ item: newItem, idx });
  };

  useEffect(() => {
    if (props.defaultValues) {
      setData(props.defaultValues);
    }
  }, [props.defaultValues]);

  useEffect(() => {
    if (deleteIdx !== -1) {
      const newData = data.filter((_, i) => i !== deleteIdx);
      setDeleteIdx(-1);

      // bug identify in react's re-render.
      // below is the trigger to fully re-render correct list in ui.
      setData([]);
      if (newData.length > 0) {
        setTimeout(() => setData(newData), 0);
      }

      props.onItemDelete(newData);
    }
  }, [deleteIdx]);

  useEffect(() => {
    if (updateIdx) {
      setUpdateIdx(undefined);
      const newList = data.map((i, idx) => (idx === updateIdx.idx ? updateIdx.item : i));

      setData([...newList]);
      props.onItemChange(updateIdx.item, updateIdx.idx, newList);
    }
  }, [updateIdx]);

  return (
    <>
      <ControlAddNew
        {...props}
        className={classes.rootAddFlex}
        add={(newString) => {
          setData(data?.concat(newString) || [newString]);
          props.onAdd(newString);
        }}
      ></ControlAddNew>
      {data.map((d, idx) => {
        return (
          <ControlEntry
            {...props}
            key={idx}
            deleteIdx={idx}
            item={d}
            className={classes.rootDeleteFlex}
            delete={handleDelete}
            changing={handleChange}
          ></ControlEntry>
        );
      })}
    </>
  );
};

export { FullTextList };
