import { createMuiTheme } from '@material-ui/core';
import { green, grey, orange } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#4b4848'
    },
    primary: {
      main: '#162845',
      light: '#0060ac'
    },
    secondary: {
      main: '#da782d',
      light: '#fef7f0'
    },
    info: {
      main: grey[500],
      light: grey[300],
      dark: grey[700]
    },
    success: {
      main: green[500],
      light: green[300],
      dark: green[700]
    },
    warning: {
      main: orange[500],
      light: orange[300],
      dark: orange[700]
    },
    action: {
      selected: '#da782d',
      hover: '#fef7f0'
    }
  },
  typography: {
    fontFamily: ['Helvetica', 'Arial'].join(','),
    fontSize: 14
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.2, 0, 0, 1)',
      easeOut: 'cubic-bezier(0.2, 0, 0, 1)'
    }
  },
  overrides: {
    MuiSwitch: {
      colorSecondary: {
        '&$checked&$disabled': {
          color: orange[200]
        }
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          color: 'black',
          backgroundColor: '#fef7f0'
        },
        '&$selected': {
          color: 'white',
          backgroundColor: '#da782d'
        }
      }
    }
  }
});
