import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Routes } from '../../feature/Routes';
import { SearchPage } from './SearchPage';
import { useLocation } from '@reach/router';
import { FeatureCategory } from '../model';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: '100%',
    overflow: 'auto',
    marginTop: '5pt'
  }
}));

const MainPage = (props) => {
  const classes = useStyle();
  const location = useLocation();
  const [showSearchPage, setShowSearchPage] = useState(false);

  useEffect(() => {
    const pathNameArray = location.pathname.split('/');
    if (!!pathNameArray && pathNameArray.length === 2) {
      const category = pathNameArray[1] as FeatureCategory;
      setShowSearchPage(!!category && category !== FeatureCategory.admin && category !== FeatureCategory.token);
    } else {
      setShowSearchPage(false);
    }
  }, [location.pathname]);

  return (
    <>
      {!!showSearchPage && <SearchPage />}
      <section className={classes.root} id="mainPage">
        <Routes></Routes>
      </section>
    </>
  );
};

export { MainPage };
