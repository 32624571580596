import React from 'react';
import { Link, LinkProps } from '@reach/router';
import { makeStyles, Theme } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) => ({
  activeNavBtn: {
    color: `${theme.palette.secondary.main} !important`
  }
}));

const NavLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => {
  const classes = useStyle();

  return (
    <Link
      {...props}
      ref={ref}
      getProps={({ isPartiallyCurrent }) => {
        return {
          className: isPartiallyCurrent ? `${classes.activeNavBtn} ${props.className}` : `${props.className}`
        };
      }}
    />
  );
});

export { NavLink };
