import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ListItemWithAction } from './ListItemWithAction';

export interface ListData {
  line1: string;
  line2: string;
  line3: string;
  id: string;
  data: any;
  link?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  itemBorder: {
    marginBottom: '8pt',
    '&:hover, &:focus': {
      backgroundColor: '#fef7f0'
    }
  },
  itemAction: {
    color: theme.palette.secondary.main
  },
  paging: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const ListPagination = (props) => {
  const classes = useStyle();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageData, setPageData] = useState([] as ListData[]);
  const handlePageChange = (pageNumber: number) => {
    const startIdx = rowPerPage * pageNumber - rowPerPage;
    const endIdx = startIdx + rowPerPage;
    const preMapData = props.items.slice(startIdx, endIdx);

    setPageData(preMapData.map(props.mapping));
    setPage(pageNumber);
  };

  useEffect(() => {
    setPageCount(props.items.length > rowPerPage ? Math.ceil(props.items.length / rowPerPage) : 1);
    handlePageChange(1);
  }, [props.items]);

  return (
    <>
      <List>
        {pageData.map((item) => (
          <ListItemWithAction
            key={item.id}
            loading={props.deleteIds?.includes(item.id) || false}
            borderClass={classes.itemBorder}
            line1={item.line1}
            line2={item.line2}
            line3={item.line3}
            actions={props.actions}
            onActionClick={(type) => props.onActionClick(type, item.data)}
            onItemClick={(_) => (props.onItemClick ? props.onItemClick(item.data) : null)}
            link={item.link}
          />
        ))}
      </List>
      {pageCount > 1 && (
        <Container maxWidth="lg">
          <Pagination
            className={classes.paging}
            size="small"
            color="secondary"
            count={pageCount}
            page={page}
            onChange={(_, p) => handlePageChange(p)}
          />
        </Container>
      )}
    </>
  );
};

export { ListPagination };
