const RequiredValidator = {
  required: 'required'
};

const registerRequiredField = (register, name) => {
  return register(name, RequiredValidator);
};

const registerRequiredValidator = (register) => {
  return register(RequiredValidator);
};

const hasAnyValidationError = (error, name) => !!(error && error[name] && error[name]['message']);

const getValidationError = (error, name) => error && error[name] && error[name]['message'];

const setDirtyValue = (setValue, name, value) => {
  setValue(name, value, {
    shouldDirty: true
  });
};

const setCustomError = (setError, name, message) => {
  setError(name, {
    type: 'custom',
    message
  });
};

const filterCustomErrors = (errors) => {
  let customErrors = {};
  errors &&
    Object.keys(errors).forEach((errorName) => {
      if (errors[errorName].type.includes('custom')) {
        customErrors[errorName] = errors[errorName];
      }
    });

  return customErrors;
};

const removeFromCustomErrors = (clearErrors, customErrors, name) => {
  clearErrors(name);

  let errors = {};
  customErrors &&
    Object.keys(customErrors).forEach((errorName) => {
      if (errorName !== name) {
        errors[errorName] = customErrors[errorName];
      }
    });

  return errors;
};

export {
  registerRequiredField,
  registerRequiredValidator,
  hasAnyValidationError,
  getValidationError,
  setDirtyValue,
  setCustomError,
  filterCustomErrors,
  removeFromCustomErrors,
  RequiredValidator
};
