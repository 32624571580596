import React, { createContext, useReducer } from 'react';

import { Client } from '../../feature/client/model';
import { FirmDetailModel } from '../../feature/firm/model';
import { User } from '../../feature/user/model';
import { FeatureCategory, SearchParams } from '../model';

enum dataStatus {
  dirt = 'init',
  green = 'data loaded',
  bush = 'data out of date'
}

export class AppActionType {
  static UPDATE_SEARCH = '[App state] update search';
  static ASK_BEFORE_NAVIGATE = '[App state] ask before navigating';
  static GET_CLIENT = '[App state] get client';
  static GET_CLIENT_SUCCESS = '[App state] get client success';
  static GET_CLIENT_FAILURE = '[App state] get client failure';
  static GET_USER = '[App state] get user';
  static GET_USER_SUCCESS = '[App state] get user success';
  static GET_USER_FAILURE = '[App state] get user failure';
  static DELETE_CLIENT_SUCCESS = '[App state] delete client success';
  static UPDATE_CLIENT_STATUS = '[App state] update client data status';
  static GET_FIRM = '[App state] get firm';
  static GET_FIRM_SUCCESS = '[App state] get firm success';
  static GET_FIRM_FAILURE = '[App state] get firm failure';
  static UPDATE_FIRM_STATUS = '[App state] update firm data status';
  static UPDATE_CATEGORY = '[App state] update category';
}

export interface AppState {
  isAdminUser: boolean;
  login: { fullName: string; id: string; email: string };
  search: SearchParams | undefined;
  askBeforeNavigate: boolean;
  category: FeatureCategory;
  client: {
    loading: boolean;
    data: Client[];
    status: dataStatus;
  };
  user: {
    loading: boolean;
    data: User[];
    status: dataStatus;
  };
  firm: {
    loading: boolean;
    data: FirmDetailModel[];
    status: dataStatus;
  };
}

export interface Action {
  payload: any;
  type: AppActionType;
}

export interface AppContext {
  appState: AppState;
  dispatch?: (action: Action) => any;
}

const initialState = {
  isAdminUser: false,
  login: { fullName: '', id: '', email: '' },
  search: undefined,
  askBeforeNavigate: false,
  category: FeatureCategory.client,
  client: {
    loading: false,
    data: [],
    status: dataStatus.dirt
  },
  user: {
    loading: false,
    data: [],
    status: dataStatus.dirt
  },
  firm: {
    loading: false,
    data: [],
    status: dataStatus.dirt
  }
};

const AppStateContext = createContext<AppContext>({ appState: initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case AppActionType.ASK_BEFORE_NAVIGATE:
      return { ...state, askBeforeNavigate: action.payload };
    case AppActionType.UPDATE_SEARCH:
      return { ...state, search: action.payload };
    case AppActionType.GET_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          loading: true
        }
      };
    case AppActionType.GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: {
          data: [...action.payload],
          loading: false,
          status: dataStatus.green
        }
      };
    case AppActionType.GET_CLIENT_FAILURE:
      return {
        ...state,
        client: {
          ...state.client,
          loading: false
        }
      };
    case AppActionType.UPDATE_CLIENT_STATUS:
      return {
        ...state,
        client: {
          ...state.client,
          status: action.payload
        }
      };
    case AppActionType.GET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true
        }
      };
    case AppActionType.GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          data: [...action.payload],
          loading: false,
          status: dataStatus.green
        }
      };
    case AppActionType.GET_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false
        }
      };
    case AppActionType.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          data: state.client.data?.filter((c) => c.id !== action.payload),
          status: dataStatus.bush
        }
      };
    case AppActionType.GET_FIRM:
      return {
        ...state,
        firm: {
          ...state.firm,
          loading: true
        }
      };
    case AppActionType.GET_FIRM_SUCCESS:
      return {
        ...state,
        firm: {
          data: [...action.payload],
          loading: false,
          status: dataStatus.green
        }
      };
    case AppActionType.GET_FIRM_FAILURE:
      return {
        ...state,
        firm: {
          ...state.firm,
          loading: false
        }
      };
    case AppActionType.UPDATE_FIRM_STATUS:
      return {
        ...state,
        firm: {
          ...state.firm,
          status: action.payload
        }
      };
    case AppActionType.UPDATE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    default:
      return state;
  }
};

const AppStateProvider = (props) => {
  const [appState, dispatch] = useReducer(reducer, initialState);

  return (
    <AppStateContext.Provider
      value={{
        appState: {
          ...appState,
          isAdminUser: props.isAdminUser,
          login: props.login
        },
        dispatch
      }}
    >
      {props.children}
    </AppStateContext.Provider>
  );
};

export { AppStateContext, AppStateProvider, dataStatus };
