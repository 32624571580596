import queryString from 'querystring';

import { FetchApiResponse, responseKO, responseOK } from './fetch-types';
import { getAccessToken } from '../auth.service';

export interface RequestOptions<T = any> {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body: T;
  params: Record<string, any>;
  headers: Record<string, string>;
  token: string;
  isResponseText?: boolean;
  skipAuth?: boolean;
  credentials?: 'include' | 'omit' | 'same-origin';
}

export async function fetchApi<T, E = any, B = any>(
  path: string,
  options: Partial<RequestOptions<B>> = {}
): Promise<FetchApiResponse<T, E>> {
  const token = options.skipAuth ? '' : await getAccessToken();

  const defaultOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(options.body ? { 'Content-Type': 'application/json' } : {})
    }
  };

  const url = `${path}${options.params ? queryString.stringify(options.params) : ''}`;

  let response;

  try {
    response = await fetch(url, {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers,
        ...options.headers
      },
      body: JSON.stringify(options.body)
    });

    const { ok, status, headers } = response;

    const contentLength = headers.get('content-length');
    const noBody = contentLength !== null && +contentLength === 0;
    const resBody = !!options.isResponseText || noBody ? await response.text() : await response.json();

    // status not in the range 200-299
    if (!ok) {
      const { message } = resBody;
      switch (status) {
        case 400:
          return responseKO({
            error: 'BadRequest',
            statusCode: status,
            errorMessage: message || ''
          });

        case 401:
          return responseKO({
            error: 'Unauthorized',
            statusCode: status,
            errorMessage: message || ''
          });

        case 403:
          return responseKO({
            error: 'AccessDenied',
            statusCode: status,
            errorMessage: message || ''
          });

        default:
          return responseKO({
            error: 'InternalServerError',
            statusCode: status,
            errorMessage: message || ''
          });
      }
    }

    // when api return 204 (no content), we would not try to convert the response to neither text or json
    if (response.status === 204) {
      return responseOK<any>(Promise.resolve(''));
    }

    return responseOK(resBody);
  } catch {
    if (response.status === 200) {
      return responseOK<any>({
        ok: true,
        value: ''
      });
    }
    return responseKO({
      error: 'InternalServerError',
      statusCode: !!response && !!response.status ? response.status : 500
    });
  }
}
