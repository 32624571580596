import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: '5pt'
  }
}));

const SimpleSelect = (props) => {
  const classes = useStyle();
  const [value, setValue] = useState(props.value);

  const handleOnChange = (e) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleOnChange}
      innerRef={props.inputRef}
      className={classes.root}
      disableUnderline={true}
    >
      {props.items.map((item) => (
        <MenuItem key={item.label} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export { SimpleSelect };
