import React, { useState, MouseEvent } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const label = {
  edit: 'Edit',
  delete: 'Delete'
};

export enum MenuItemAction {
  edit = 'edit',
  delete = 'delete'
}

export type BtnMenuIconProps = {
  actions: Array<MenuItemAction>;
  onActionClick: (action) => void;
};

const BtnMenuIcon = (props: BtnMenuIconProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClickOnItem = (event: MouseEvent<HTMLLIElement>, selectedAction: string) => {
    handleClose(event);
    props.onActionClick(selectedAction);
  };

  return (
    <div>
      <IconButton aria-controls="btn-menu-icon" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="btn-menu-icon" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.actions.map((selectedAction: string) => {
          return (
            <MenuItem key={selectedAction} onClick={(event) => handleClickOnItem(event, selectedAction)}>
              {label[selectedAction]}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export { BtnMenuIcon };
