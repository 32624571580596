import React, { lazy, Suspense } from 'react';

const IconButton = lazy(() => import('@material-ui/core/IconButton'));

const iconType = {
  search: 'search',
  exitToApp: 'exitToApp',
  moreVert: 'moreVert',
  addCircle: 'addCircle',
  delete: 'delete',
  fileCopy: 'fileCopy',
  redo: 'redo'
};

const CustomIcon = (icon: string, size: 'inherit' | 'default' | 'large' | 'small' | undefined) => {
  let RequestIcon;

  switch (icon) {
    case iconType.search:
      RequestIcon = lazy(() => import('@material-ui/icons/Search'));
      break;
    case iconType.exitToApp:
      RequestIcon = lazy(() => import('@material-ui/icons/ExitToApp'));
      break;
    case iconType.moreVert:
      RequestIcon = lazy(() => import('@material-ui/icons/MoreVert'));
      break;
    case iconType.addCircle:
      RequestIcon = lazy(() => import('@material-ui/icons/AddCircle'));
      break;
    case iconType.delete:
      RequestIcon = lazy(() => import('@material-ui/icons/Delete'));
      break;
    case iconType.fileCopy:
      RequestIcon = lazy(() => import('@material-ui/icons/FileCopyOutlined'));
      break;
    case iconType.redo:
      RequestIcon = lazy(() => import('@material-ui/icons/Redo'));
      break;
    default:
      RequestIcon = lazy(() => import('@material-ui/icons/Info'));
  }

  return <RequestIcon fontSize={size} />;
};

const BtnIcon = (props: {
  icon: 'search' | 'exitToApp' | 'moreVert' | 'addCircle' | 'delete' | 'fileCopy' | 'redo' | undefined;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  className?: string;
  size: 'inherit' | 'default' | 'large' | 'small' | undefined;
  title?: string;
  onClick: (event: any) => void;
  disabled?: boolean;
}) => {
  return (
    <Suspense fallback={null}>
      <IconButton
        aria-label={props.icon || 'info'}
        className={props.className}
        onClick={props.onClick}
        title={props.title}
        color={props.color}
        disabled={props.disabled || false}
      >
        {props.icon && iconType[props.icon] ? CustomIcon(props.icon, props.size) : null}
      </IconButton>
    </Suspense>
  );
};

export { BtnIcon };
