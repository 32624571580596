import React, { useContext } from 'react';

import './App.css';
import { AppStateProvider, EAuthAdminRole } from './core/context';
import { ErrorBoundary, MainLayout } from './core/component';
import { AuthContext } from './core/context';
import { Logger } from './core/service';

function App() {
  const authContext = useContext(AuthContext);
  Logger.info('AuthContext: ', authContext);
  return (
    <AppStateProvider
      isAdminUser={authContext.role === EAuthAdminRole.Admin}
      login={{
        fullName: authContext.fullName,
        id: authContext.id,
        email: authContext.email
      }}
    >
      <ErrorBoundary></ErrorBoundary>
      <MainLayout></MainLayout>
    </AppStateProvider>
  );
}

export default App;
