import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import {
  FeatureCategory,
  QuickSearchLink,
  QuickSearchOption,
  QuickSearchParamKey,
  QuickSearchSplitByDelimiter
} from '../model/search.model';

export const useSearch = (): [FeatureCategory | undefined, string, string, QuickSearchOption[], string[]] => {
  const location = useLocation();
  const [category, setCategory] = useState<FeatureCategory | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [quickSearch, setQuickSearch] = useState<string>('');
  const [quickSearchOptions, setQuickSearchOptions] = useState<QuickSearchOption[]>([]);
  const [quickSearchCriteria, setQuickSearchCriteria] = useState<string[]>([]);

  const readQueryParam = (searchTerm, queryKey) => {
    const value = searchTerm?.find((x) => x.indexOf(queryKey) !== -1)?.split(queryKey)[1] || '';
    return decodeURIComponent(value);
  };

  useEffect(() => {
    const c = location.pathname.split('/')[1] || FeatureCategory.client;
    setCategory(c);
    setQuickSearchOptions([]);
    setQuickSearchCriteria([]);
  }, [location.pathname]);

  useEffect(() => {
    const s = location.search.split('&');
    const searchTermFromLocation = readQueryParam(s, QuickSearchParamKey.searchTermKey);
    const quickSearchFromLocation = readQueryParam(s, QuickSearchParamKey.quickSearchKey);
    setSearchTerm(searchTermFromLocation);
    setQuickSearch(quickSearchFromLocation);
  }, [location.search]);

  useEffect(() => {
    if (!category) {
      return;
    }

    let options: QuickSearchOption[] = QuickSearchLink[category];
    if (!options) {
      setQuickSearchOptions([]);
      setQuickSearchCriteria([]);
      return;
    }

    if (!!quickSearch) {
      const terms = quickSearch.split(QuickSearchSplitByDelimiter) || [];
      options = options!.map((o) => ({
        ...o,
        selected: terms.indexOf(o.qsKey) !== -1
      }));
    }

    const criteria = options.filter((o) => o.selected).map((o) => o.qsKey);
    setQuickSearchOptions(options);
    setQuickSearchCriteria(criteria);
  }, [category, quickSearch]);

  return [category, searchTerm, quickSearch, quickSearchOptions, quickSearchCriteria];
};
