import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Controller } from 'react-hook-form';

const ControlledJsonEditor = (props) => {
  const [value, setValue] = useState();
  const [jsonError, setJsonError] = useState<string>();

  useEffect(() => {
    if (!value && props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  if (!props.label || !props.id || props.control === undefined) {
    return null;
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue || null}
      render={({ onChange, value }, { invalid, isTouched, isDirty }) => (
        <FormControl style={{ height: '100%', width: '100%' }}>
          <InputLabel shrink={true} htmlFor={`${props.id}-jsoneditor`} error={props.error || !!jsonError || false}>
            {props.label}
          </InputLabel>
          <JSONInput
            id={`${props.id}-jsoneditor`}
            placeholder={value}
            locale={locale}
            style={{
              outerBox: { paddingTop: '1.5rem' },
              warningBox: { display: 'none' }, // purposely hide the default warning.
              body: { height: '100%' } // after hiding the default warning.
            }}
            viewOnly={props.viewOnly || false}
            onKeyPressUpdate={false}
            onBlur={({ error, jsObject }) => {
              if (!error) {
                setJsonError('');
                onChange(jsObject);
                props.onErrorClear && props.onErrorClear();
                return;
              }

              const errorMessage = `${error.reason} at line ${error.line}`;
              setJsonError(errorMessage);

              props.onError && props.onError(errorMessage);
            }}
            confirmGood={false}
            height="100%"
            width="100%"
          />
          <FormHelperText error={props.error || !!jsonError || false}>
            {props.helperText || jsonError || ''}
          </FormHelperText>
        </FormControl>
      )}
    ></Controller>
  );
};

export { ControlledJsonEditor };
