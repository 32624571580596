import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      padding: 0
    },
    option: {
      // Hover
      '&[data-focus="true"]': {
        color: 'black',
        backgroundColor: '#fef7f0'
      },
      // Selected
      '&[aria-selected="true"]': {
        color: 'white',
        backgroundColor: '#da782d'
      }
    }
  })
);

interface SelectOption {
  label: string;
  key: string;
  data: any;
}

const FullTextAutocomplete = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>();
  const [rawOptions, setRawOptions] = useState([]);

  const handleOpenToggle = () => setOpen(!open);

  useEffect(() => {
    if (!loading && open && !options) {
      setLoading(true);
      if (!!props.optionSource) {
        props.optionSource().then((res) => {
          if (res.ok) {
            setRawOptions(res.value);
            const mappedOptions = res.value.map(props.optionMapping);

            // handle previous added scope but not in the list.
            // inject them if something new.
            const includedManualOptions =
              (props.multiple
                ? props.defaultValue.filter((m) => !mappedOptions.find((x) => x.key === m.key))
                : mappedOptions.findIndex((x) => x.key === props.defaultValue.key) === -1 && [props.defaultValue]) ||
              [];

            setOptions(includedManualOptions.concat(mappedOptions));
          }

          setLoading(false);
        });
      } else {
        setOptions(props.options);
        setLoading(false);
      }
    }
  }, [open, options]);

  // not to load any value after defaultValue loaded.
  // defaultValue can be null or object.
  if (props.defaultValue === undefined) {
    return null;
  }

  return (
    <Autocomplete
      classes={{
        option: classes.option,
        listbox: classes.listbox
      }}
      id={props.id || undefined}
      size={props.size}
      disabled={props.disabled}
      multiple={props.multiple || false}
      open={open}
      onChange={(_, data) => props.onChange(data)}
      onOpen={handleOpenToggle}
      onClose={handleOpenToggle}
      getOptionSelected={(option, value) => {
        if (typeof option === 'string') {
          return option === value;
        } else {
          return option.key === value.key;
        }
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        } else {
          return option.label;
        }
      }}
      defaultValue={props.defaultValue || null}
      options={options || []}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            error={props.error}
            helperText={props.helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        );
      }}
    />
  );
};

export { FullTextAutocomplete };
