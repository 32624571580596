import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import { BtnMenuIcon, BtnMenuIconProps } from '../button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from '../navLink/NavLink';

type ListItemWithActionProps = Partial<BtnMenuIconProps> & {
  link?: string;
  borderClass: string;
  loading: boolean;
  line1: string;
  line2: string;
  line3: string;
  onItemClick?: (event: React.MouseEvent) => void;
};

const ListItemWithLink: React.FunctionComponent<{ link?: string }> = (props) => {
  const { link, children } = props;
  return link ? (
    <ListItem alignItems="flex-start" button component={NavLink} to={link}>
      {children}
    </ListItem>
  ) : (
    <ListItem alignItems="flex-start">{children}</ListItem>
  );
};

const ListItemWithAction = (props: ListItemWithActionProps) => {
  const btnMenuIcon =
    props.actions && !!props.onActionClick ? (
      <BtnMenuIcon actions={props.actions} onActionClick={props.onActionClick}></BtnMenuIcon>
    ) : null;

  return (
    <Container maxWidth="lg">
      <Paper className={props.borderClass}>
        <ListItemWithLink link={props.link}>
          <ListItemText
            style={{ cursor: props.loading ? '' : 'pointer' }}
            onClick={(event) => (props.onItemClick ? props.onItemClick(event) : null)}
            primary={props.line1}
            secondary={
              <>
                <Typography component="span" variant="body2" color="textPrimary">
                  {props.line2}
                </Typography>
                {' ' + props.line3}
              </>
            }
          />
          {props.loading ? (
            <CircularProgress size={24} style={{ position: 'absolute', right: '1.5rem', top: '1.2rem' }} />
          ) : (
            btnMenuIcon
          )}
        </ListItemWithLink>
      </Paper>
    </Container>
  );
};

export { ListItemWithAction };
