import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState, ChangeEvent, useEffect } from 'react';

const FullSelect = (props) => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (props && props.onChange) {
      props.onChange(newValue);
    }
  };

  useEffect(() => {
    if (!value) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <TextField
      {...props}
      value={value}
      fullWidth
      select
      onChange={handleChange}
      InputLabelProps={{
        shrink: true
      }}
    >
      {props.options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export { FullSelect };
