import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { BtnText } from '../button';
import { ScrollShowAppBar } from './ScrollShowAppBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    }
  })
);

const handleScrollToView = (e, elementId) => {
  const anchor = ((e.target as HTMLDivElement).ownerDocument || document).querySelector(elementId);

  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const AnchorLinkBar = (props) => {
  const classes = useStyles();
  const getSaveOrCreateLabel = (isNew: boolean, isSaving: boolean) => {
    if (isSaving) {
      return isNew ? 'Creating...' : 'Saving...';
    }
    return isNew ? 'Create' : 'Save';
  };

  return (
    <ScrollShowAppBar alwaysAppear={true} targetRef={document.querySelector('#mainPage')}>
      <div className={classes.root}>
        <section data-testid="tab-links">
          {props.anchors.map((link, idx) => {
            const [label, anchor] = link;

            return (
              <BtnText key={idx} color="primary" size="small" onClick={(e) => handleScrollToView(e, anchor)}>
                {label}
              </BtnText>
            );
          })}
        </section>
        {(props.handleCancel || props.handleSave) && (
          <section data-testid="tab-actions">
            <BtnText onClick={props.handleCancel} disabled={props.isSaving} size="small" color="secondary">
              Cancel
            </BtnText>
            <BtnText
              disabled={props.isSaving || props.disableSave}
              onClick={props.handleSave}
              size="small"
              color="primary"
            >
              {getSaveOrCreateLabel(props.isNew, props.isSaving)}
            </BtnText>
          </section>
        )}
      </div>
    </ScrollShowAppBar>
  );
};

export { AnchorLinkBar };
