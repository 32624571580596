const AuthConf = {
  authHost: process.env.REACT_APP_AUTH_HOST!,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
  scopes: ['profile,AUTH:*:*,AUTH:user:unlock', 'offline_access'],
  autoLogin: true,
  autoLogout: true,
  uniqueSession: true
};

export { AuthConf };
